import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import ContactusPage from "./pages/ContactusPage";
import AboutPage from "./pages/AboutPage";
import Layout from "./layout/layout";
import CourseLandingSection from "./components/courseComponent/courseLandingSection";
import Aos from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./components/uiComponents/ScrollToTop";
import MaintancePage from "./pages/MaintancePage";
import Login from "./pages/Login";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import ProtectedRoute from "./protectedRoute/ProtectedRoute";
import Notiflix from "notiflix";
import Dashboard from "./pages/Dashboard";
const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false, // Allow animations to repeat
      mirror: true, // Whether elements should animate out while scrolling past them
    });
    Notiflix.Notify.init({
      position: "center-top",
    });

    const handleScroll = () => {
      Aos.refresh(); // Recalculate positions of elements for AOS animations
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="App">
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <ContactusPage />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <AboutPage />
            </Layout>
          }
        />
        <Route
          path="/courses"
          element={
            <Layout>
              <CourseLandingSection />
            </Layout>
          }
        />
        <Route
          path="/blogs"
          element={
            <Layout>
              <MaintancePage />
            </Layout>
          }
        />
        <Route
          path="/terms&policies"
          element={
            <Layout>
              <TermsAndConditionPage />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
