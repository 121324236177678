import React from 'react'
import Icon from '../assets/bulls-white.png'
import { Link } from 'react-router-dom'

const MaintancePage = () => {
  return (
    <section className='bg-black w-screen h-screen'>
    <div className='flex justify-center flex-col gap-y-3 items-center h-full'>
      <img src={Icon} alt='logo' className='h-24 w-auto' />
    <h1 className='text-3xl font-bold text-white gruppo'>Blogs Comming Soon !</h1>
    <h3 className='text-red-600'>⚠️ Website is Under Construction</h3>
    <Link to={"/"}><h3 className='text-white underline s'>Get Back To Home Page ¬ </h3></Link>
    </div>
    </section>
  )
}

export default MaintancePage