import React from "react";
import toast from "react-hot-toast";
import axiosClient from "../../webServices/webUrls";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

const DashboardNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const res = await toast.promise(axiosClient.post("/admin/logout"), {
        loading: "Logging in...",
        success: () => {
          Cookies.remove("token");
          navigate("/login");
          return "Login successful";
        },
        error: "Login failed. Please try again.",
      });
    } catch (error) {
      toast.error("Please try again");
      console.log(error.message);
    }
  };

  return (
    <header className="bg-gray-800">
      <nav className="container mx-auto px-6 py-3">
        <div className="flex items-center justify-between">
          <div className="text-white font-bold text-xl">
            <div className="h-full flex items-center gap-2">
              <img
                className="w-[20%] lg:w-[50%] max-w-[80px] lg:max-w-[50px]"
                src="https://mindbulls.com/static/media/bulls-white.6bc8e52f157badd3c97d.png"
                alt=""
              />
              <img
                className="w-[25%] lg:w-[50%] max-w-[200px] lg:max-w-[150px]"
                src="https://mindbulls.com/static/media/logo-label-white.f8092bd322d1f60c3598.png"
                alt=""
              />
            </div>
          </div>
          <div>
            <button
              onClick={handleLogout}
              className="bg-red-500 px-5 py-2 hover:scale-105 transition-all rounded-xl text-white font-bold"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default DashboardNavbar;
