export const navBarItems = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Courses",
    path: "/courses",
  },
  {
    title: "Blog",
    path: "/blogs",
  },
  {
    title: "Demat",
    path: "https://iserve-partners.indiainfoline.com/common/openaccount?E1Code=c6jPF7NK5GMc%209jSoBNwwQ%3D%3D&SourceChannelID=KBWtK3WkgyXc2SanaseYhg%3D%3D",
  },
  {
    title: "About us",
    path: "/about",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];

