import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("token");

const axiosClient = axios.create({
  baseURL: "https://api.mindbulls.com/api",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axiosClient;