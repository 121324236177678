import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import RatingStars from "../uiComponents/RatingStars";

const testimonials = [
  {
    id: 1,
    rating: 5,
    review:
      "Excellent teaching , it was very wonderful experience. i learned whole system of Market how it works   like price action  how to read chart , candlestick , chart pattern , option chain , i am grateful to i joined this institute i paid minimum amount  of fees .",
    avatar:
      "https://lh3.googleusercontent.com/a/ACg8ocLJyVPlyjziNorn_AIW_8Zum4IpbTaZIT_Px2ayEOdOGTln1g=w45-h45-p-rp-mo-br100",
    name: "Ashish Badole",

  },
  {
    id: 2,
    rating: 5,
    review:
      "Chandan sir's guidance has been great....I really had many doubts sir solved....nice work.",
    avatar: "https://lh3.googleusercontent.com/a-/ALV-UjXg9XmoL3AGBD116oJgdHnuxC7QgxquJrFKgwJkA_TX03fhR18V=s40-c-rp-mo-br100",
    name: "rajkumar singawne",
  },
  {
    id: 3,
    rating: 5,
    review:
      "It was a great experience for me to join this fabulous stock marketing class. According to me this is the best place to know more about the stock market and become a Big BULL",
    avatar: "https://lh3.googleusercontent.com/a-/ALV-UjU-LpqUu9SiJNdJL2Wsg_KxpXKKaRgkk-qYdeHyBslLvr2BHD0q=w45-h45-p-rp-mo-br100",
    name: "Lucky Rajput",
  },
  {
    id: 4,
    rating: 4.9,
    review:
      "Instructors explained all the things in details. Cleared all query and doubts. Teaching in very common understandable language. Person with No stock market knowledge can also get a confidence after course completion.Highly Recommended....!!",
    avatar: "https://lh3.googleusercontent.com/a-/ALV-UjUOXfcLsp9NQyB47mIZSaTc4ReMwaXSnwmmCSZOWHJRBdPknuSE=w75-h75-p-rp-mo-br100",
    name: "Priya",
  },
  {
    id: 5,
    rating: 4.9,
    review:
      "I am pursuing BPT , but I want to learn stock market so I join mindbulls class . Very satisfying knowledge and learning.Knowledge about price action and option data is very excellent .Thank you.",
    avatar: "https://lh3.googleusercontent.com/a-/ALV-UjXM9QFKUfXhlgwKcGf-h4qRMNvSs2moTTCq31HfsApNcTbgvKA=w75-h75-p-rp-mo-br100",
    name: "Ankit Patel",
  },
  {
    id: 6,
    rating: 5,
    review:
      "Superb Coaching....!!!!More than value for money.Highly Recommended....!!!!",
    avatar: "https://lh3.googleusercontent.com/a/ACg8ocI24IJd13JiFDzu4yC30lRBm7-3LBqajno4hqnvElLa3b02jA=w75-h75-p-rp-mo-br100",
    name: "Raginee Gopal",
  },
  {
    id: 7,
    rating: 5,
    review:
      "Great trading skills faculty. Learn lot of there. One can join with low fees and high learning skills",
    avatar: "https://lh3.googleusercontent.com/a-/ALV-UjUp3Xk6tcqiilmPz0ToRXy8UarnqV3MkIRppDzkbT-p6dwxbMg1oQ=w75-h75-p-rp-mo-br100",
    name: "Anjali Urdheya",
  },
];

const TestimonialCarousel = () => {
  return (
    <section className="lg:p-14 py-7 bg-[#d3d3d345]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mb-16">
          <span className=" text-gray-500 text-2xl font-medium text-center block mb-2">
            TESTIMONIAL
          </span>
          <h2 className="text-4xl text-center font-bold text-gray-900">
            What our happy members says!
          </h2>
        </div>
        <Swiper
          className="mySwiper"
          slidesPerView={1}
          spaceBetween={32}
          modules={[Autoplay, Pagination]}
          loop={true}
          centeredSlides={true}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 32,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="group flex flex-col-reverse justify-end bg-white h-96 border border-solid border-gray-300 rounded-xl p-6 transition-all duration-500 w-full mx-auto hover:border-indigo-600 hover:shadow-sm">
                <div className="mt-4">
                  <div className="flex flex-col mb-7 gap-2 text-amber-500 transition-all duration-500">
                  <RatingStars rating={testimonial.rating} />
                    <div className="text-base font-semibold text-indigo-600">
                     Rating : {testimonial.rating} / 5
                    </div>
                  </div>
                  <p className="text-base text-gray-600 leading-6 transition-all duration-500 pb-8 group-hover:text-gray-800">
                    {testimonial.review}
                  </p>
                </div>
                <div className="flex items-center gap-5 border-b  pb-3 border-solid border-gray-200 pt-2">
                  <img
                    className="rounded-full h-10 w-10"
                    src={testimonial.avatar}
                    alt="avatar"
                  />
                  <div className="block">
                    <h5 className="text-gray-900 font-medium transition-all duration-500 mb-1">
                      {testimonial.name}
                    </h5>
                    <span className="text-sm leading-4 text-gray-500">
                      {testimonial.position}
                    </span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default TestimonialCarousel;
