import React from "react";

const InfoCards = ({icon,heading,description}) => {
  return (
   
      <div className="py-8 px-2 h-fit w-4/5 m-auto glowEffect mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
        <div className="inline-block text-gray-900 mb-4">
          {/* icon */}
          <img width={50} height={50} src={icon} />
        </div>
        <h3 className="text-lg leading-normal mb-2 font-semibold text-black">
          {heading}
        </h3>
        <p className="text-gray-500 line-clamp-2">
          {description}
        </p>
      </div>
    
  );
};

export default InfoCards;
