import React, { useEffect, useState } from "react";
import NavBar from "../components/headerComponents/NavBar";
import Footer from "../components/Footer";
import WhatsApp from "../components/uiComponents/WhatsApp";


const Layout = ({ children }) => {
  const path = window.location.pathname
  console.log(path)
  return (
    <>
      {!path.includes('login' || 'dashboard') && <NavBar />}
      {!path.includes('login' || 'dashboard') && <WhatsApp />}
      <main>
          {children}
      </main>
      {!path.includes('login' || 'dashboard')&&<Footer />}
    </>
  );
};

export default Layout;
