import React from 'react'
import icon from "../../assets/icon/missionIcon.png"
function AboutSection3() {
  return (
    <>
      <section className="help_content">
        <div className="container">
          <div className="help_content_box">
          <h1 className='h1 text-white flex flex-col items-center'><img className=' invert' src={icon} width={50} height={"auto"} alt='missionIcon' /> Mission</h1>
            <h2 className='h2'>
           <q> Enabling financial success through practical trading education. </q>
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutSection3
