import React from "react";
import { FaPhoneAlt, FaTelegram } from "react-icons/fa";
import background from "../assets/footer.jpg";
import {
  FaFacebookF,
  FaInstagram,
  FaLocationDot,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import logo from "../assets/mindbulls white front with clear background.png";

export default function Footer() {
  return (
    <footer style={{ background: `url('${background}')` }}>
      <div className=" flex lg:flex-row flex-col items-center lg:items-start backdrop:blur-sm flex-wrap lg:pt-16 lg:pb-16 bg-[#050913d4] justify-around w-full pb-4">
        <div>
          <img src={logo} alt="logo" className="lg:w-40 lg:h-40 w-56 h-56" />
        </div>
        <div
          id="footer_links_container"
          className="flex w-4/5 justify-around lg:flex-row flex-col items-start"
        >
          <div className=" text-white">
            <h1 className="text-2xl font-semibold text-yellow-600 text-pretty text">
              Navigation
            </h1>
            <ul>
              <Link to={"/"}>
                <li className="cursor-pointer hover:text-gray-300 duration-150 hover:scale-[1.09]">
                  Home
                </li>
              </Link>
              <Link to={"/courses"}>
                <li className="cursor-pointer hover:text-gray-300 duration-150 hover:scale-[1.09]">
                  Course
                </li>
              </Link>
              <Link to={"/blogs"}>
                <li className="cursor-pointer hover:text-gray-300 duration-150 hover:scale-[1.09]">
                  Blogs
                </li>
              </Link>
              <Link to={"/"}>
                {" "}
                <li className="cursor-pointer hover:text-gray-300 duration-150 hover:scale-[1.09]">
                  Demat
                </li>
              </Link>
              <Link to={"/about"}>
                {" "}
                <li className="cursor-pointer hover:text-gray-300 duration-150 hover:scale-[1.09]">
                  About
                </li>
              </Link>
              <Link to={"/contact"}>
                {" "}
                <li className="cursor-pointer hover:text-gray-300 duration-150 hover:scale-[1.09]">
                  Contact
                </li>
              </Link>
            </ul>
          </div>
          <div className=" text-white lg:w-1/3 xl:w-1/2">
            <h1 className="text-2xl font-semibold text-yellow-600 text-pretty text">
              Get In Touch
            </h1>
            <ul>
              <li className="my-2">
                <div className="flex gap-2 items-baseline">
                  <FaLocationDot />{" "}
                  <p className="items-center uppercase">M3 sairam plaza, mangal nagar near rajiv gandhi square indore</p>
                </div>
              </li>
              <li className="my-2">
                <div className="flex gap-2 items-baseline">
                  <MdEmail />
                  <a href="mailto:mindbulls02@gmail.com">
                    <p className=" justify-center">mindbulls02@gmail.com</p>
                  </a>
                </div>
              </li>
              <li className="my-2">
                <div className="flex gap-2 items-baseline">
                  <FaPhoneAlt />
                  <a href="tel:8815019860">
                    <p>+91-8815019860</p>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className=" text-white">
            <h1 className="text-2xl font-semibold text-yellow-600 text-pretty text">
              Terms & Policies
            </h1>
            <ul className="flex flex-col gap-2">
              <li>
                <div className="flex gap-2 mt-2 items-baseline">
                  <h3>Privacy Policy</h3>
                </div>
              </li>
              <Link to={"/terms&conditions"}>
                <li>
                  <div className="flex gap-2 items-baseline">
                    <h3>Terms of use</h3>
                  </div>
                </li>
              </Link>
              <li>
                <div className="flex gap-2 items-baseline">
                  <h3>Disclaimer</h3>
                </div>
              </li>
            </ul>
          </div>
          <div className=" text-white h-40 flex flex-col gap-8">
            <h1 className="text-2xl font-semibold text-yellow-600 text-pretty text">
              Social Links
            </h1>

            <ul className="flex gap-3 justify-around">
              <li className=" border-2 p-2 ">
                <Link
                  to={"https://www.facebook.com/MindBulls02/"}
                  className="glowEffect"
                >
                  <FaFacebookF size={20} color="white" />
                </Link>
              </li>
              <li className=" border-2 p-2">
                <Link
                  to={"https://www.instagram.com/mindbulls_trading_institute/"}
                  className="glowEffect"
                >
                  <FaInstagram size={20} color="white" />
                </Link>
              </li>
              <li className=" border-2 p-2">
                <Link
                  to={"https://t.me/mindbullstrading"}
                  className="glowEffect"
                >
                  <FaTelegram size={20} color="white" />
                </Link>
              </li>
              <li className=" border-2 p-2">
                <Link
                  to={"https://www.youtube.com/@mindbullstrading"}
                  className="glowEffect"
                >
                  <FaYoutube size={20} color="white" />
                </Link>
              </li>
              <li className=" border-2 p-2">
                <Link
                  to={"https://api.whatsapp.com/send?phone=918815019860"}
                  className="glowEffect"
                >
                  <FaWhatsapp size={20} color="white" />
                </Link>
              </li>
            </ul>
            <p className="text-white text-xs">© 2020 All rights reserved</p>

            {/*              
                    <search>
                        <input type="text" placeholder="Search" className="search-input" />
                    </search> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
