import React from "react";
import { FaPhoneAlt, FaTelegram } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { ErrorMessage } from "@hookform/error-message";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Notiflix from "notiflix";
import axios from "axios";
import  axiosClient  from "../webServices/webUrls";

const FormSection = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    Notiflix.Loading.hourglass();
    try {
      const res = await axiosClient.post("add/enquiry/add",data)
      if (res.status !== 200 || res?.data?.status === false) {
        throw new Error("failed to send Enquiry , Try Again");
      }

      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success(res?.data?.msg);
      }, 2000);
      reset();
    } catch (error) {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error.message);
      }, 1000);
    }
  };
  return (
    <section className="flex flex-col h-fit contact-bg items-center">
      <h1 className="h1 p-10 text-black " data-aos="fade-down">
        Get in touch with us !
      </h1>
      <div className="grid lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 grid-cols-1 md:grid-cols-1">
        <div
          className="flex flex-col justify-center items-center gap-2 text-black my-8 "
          data-aos="fade-up"
        >
          <FaPhoneAlt />
          <strong>PHONE</strong>
          <p className="text-black font-bold">+91 8815019860</p>
        </div>
        <div
          className="flex flex-col px-12 justify-center items-center gap-2 lg:border-l-2 text-black my-8"
          data-aos="fade-up"
        >
          <FaLocationDot />
          <strong>ADDRESS</strong>
          <p className="text-black font-bold text-center ">
            {" "}
            M3 sairam plaza, mangal nagar near rajiv gandhi square indore
          </p>
        </div>
        <div
          className="flex flex-col justify-center items-center gap-2 lg:border-l-2 text-black my-8"
          data-aos="fade-up"
        >
          <MdEmail />
          <strong>EMAIL</strong>
          <p className="text-black font-bold">mindbulls02@gmail.com</p>
        </div>
      </div>
      <p className="text-center text-yellow-600 mb-8">
        IF YOU GOT ANY QUESTIONS <br /> PLEASE DO NOT HESITATE TO SEND US A
        MESSAGE
      </p>
      {/* =============================Form section ======================= */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col flex-wrap gap-y-3  items-center justify-center mt-2  p-9 w-5/6"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-3 w-full">
          {" "}
          <div className="flex flex-col lg:col-span-2 col-span-1 justify-start ">
            <label for="name" className="font-bold text-xl">
              Full Name
            </label>
            <input
              placeholder="Full Name"
              id="name"
              {...register("name", { required: "name is  required" })}
              className="outline outline-2 outline-gray-400  p-2 my-2 bg-gray- 300 text-black"
              type="text"
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <p className="text-red-600">*{message}</p>
              )}
            />
          </div>
          <div className="flex flex-col justify-start ">
            <label className="font-bold text-xl" for="email">
              Email
            </label>
            <input
              placeholder="Enter your Email id"
              id="email"
              {...register("email", { required: "Email is  required" })}
              className=" outline outline-2 outline-gray-400 p-2 my-2 bg-gray- 300 text-black"
              type="text"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <p className="text-red-600">*{message}</p>
              )}
            />
          </div>
          <div className="flex flex-col justify-start ">
            <label className="font-bold text-xl" for="mobile">
              mobile No
            </label>
            <input
              placeholder="Enter your Mobile number"
              id="mobile"
              maxLength={10}
              {...register("mobile", { required: "Mobile no. is  required" })}
              className="outline outline-2 outline-gray-400 p-2 my-2 bg-gray- 300 text-black"
              type="text"
            />
            <ErrorMessage
              errors={errors}
              name="mobile"
              render={({ message }) => (
                <p className="text-red-600">*{message}</p>
              )}
            />
          </div>
          <div className="flex flex-col justify-start ">
            <label className="font-bold text-xl" for="course">
              Course
            </label>
            <select
              id="course"
              {...register("course", { required: "Mobile no. is  required" })}
              className="outline outline-2 outline-gray-400 p-2 my-2 bg-gray- 300 text-black"
            >
              <option>--Select Course--</option>
              <option value={"Basic Course"}>Basic Course</option>
              <option value={"Advance Course"}>Advance Course</option>
              <option value={"Cerification and Other"}>
                Cerification and Other
              </option>
              <option value={"Option Trading"}>Option Trading</option>
            </select>
            <ErrorMessage
              errors={errors}
              name="course"
              render={({ message }) => (
                <p className="text-red-600">*{message}</p>
              )}
            />
          </div>
          <div className="flex flex-col justify-start ">
            <label className="font-bold text-xl" for="city">
              City
            </label>
            <select
              id="city"
              {...register("city", {
                required: "city is  required if not in list select 'other'",
              })}
              className="outline outline-2 outline-gray-400 p-2 my-2 bg-gray- 300 text-black"
            >
              <option>--Select City--</option>
              <option value="Other">Other</option>
              <option value="Indore">Indore</option>
              <option value="Ujjain">Ujjain</option>
              <option value="Bhopal">Bhopal</option>
              <option value="Dewas">Dewas</option>
              <option value="Khargone">Khargone</option>
              <option value="Barwaha">Barwaha</option>
              <option value="Dhar">Dhar</option>
              <option value="Dhamnod">Dhamnod</option>
              <option value="Maheshwar">Maheshwar</option>
              <option value="Sanwer">Sanwer</option>
              <option value="Depalpur">Depalpur</option>
              <option value="Hatod">Hatod</option>
              <option value="Betma">Betma</option>
              <option value="Manpur">Manpur</option>
              <option value="Sanawad">Sanawad</option>
              <option value="Vadodara">Vadodara</option>
              <option value="Jabalpur">Jabalpur</option>
              <option value="Sagar">Sagar</option>
              <option value="Balaghat">Balaghat</option>
              <option value="Delhi">Delhi</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Pune">Pune</option>
            </select>
            <ErrorMessage
              errors={errors}
              name="city"
              render={({ message }) => (
                <p className="text-red-600">*{message}</p>
              )}
            />
          </div>
          <div className="flex  flex-col lg:col-span-2 col-span-1 justify-start ">
            <label className="font-bold text-xl" for="message">
              Message
            </label>
            <textarea
              placeholder="Leave a message for us"
              id="message"
              {...register("message")}
              className="outline outline-2 outline-gray-400 p-2 my-2 bg-gray- 300 text-black"
              type="text"
            />
          </div>
        </div>
        <button
          id="form-btn"
          className=" px-6 py-2 rounded-md duration-200 hover:shadow-2xl bg-slate-900 border-solid  shadow-md text-white w-fit "
        >
          Submit Form
        </button>
      </form>
      <h1 className="text-yellow-600 mb-8">Connect With Us !</h1>
      <ul className="flex gap-3 justify-around mb-6">
              <li className="  border-2 border-black p-2 glowEffect">
                <Link
                  to={"https://www.facebook.com/MindBulls02/"}
                  className="glowEffect"
                >
                  <FaFacebookF size={20} color="black" />
                </Link>
              </li>
             <li className=" border-2 border-black p-2 glowEffect">
                <Link
                  to={"https://www.instagram.com/mindbulls_trading_institute/"}
                  className="glowEffect"
                >
                  <FaInstagram size={20} color="black" />
                </Link>
              </li>
             <li className=" border-2 border-black p-2 glowEffect">
                <Link
                  to={"https://t.me/mindbullstrading"}
                  className="glowEffect"
                >
                  <FaTelegram size={20} color="black" />
                </Link>
              </li>
             <li className=" border-2 border-black p-2 glowEffect">
                <Link
                  to={"https://www.youtube.com/@mindbullstrading"}
                  className="glowEffect"
                >
                  <FaYoutube size={20} color="black" />
                </Link>
              </li>
             <li className=" border-2 border-black p-2 glowEffect">
                <Link
                  to={"https://api.whatsapp.com/send?phone=918815019860"}
                  className="glowEffect"
                >
                  <FaWhatsapp size={20} color="black" />
                </Link>
              </li>
            </ul>
    </section>
  );
};

export default FormSection;
