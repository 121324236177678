import React, { useEffect } from "react";
import Table from "../components/Admin/Table";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import DashboardNavbar from "../components/Admin/DashboardNavbar";

const Dashboard = () => {
  let token = Cookies.get("token");
  let admin = Cookies.get("admin");
  useEffect(() => {
   
  }, [token, admin]);
  return (
    <>
    {!token || admin !== "mbadmin24" && <Navigate to={"/login"} />}
      <DashboardNavbar />
      <Table />
    </>
  );
};

export default Dashboard;
