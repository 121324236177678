import React from "react";
import { cardData } from "../../data/courseData";

const CompanyCarousel = () => {
  return (
  <div className="flex justify-center">  <div
      x-data="{}"
      x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
      class="w-full lg:w-2/3  mx-auto bg-white lg:h-56 h-32 inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
    >
      <ul x-ref="logos" class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
        {cardData.map((item) => (
          <li className="py-2">
            <img src={item.icon} className="h-8 lg:h-12" alt="Facebook" />
          </li>
        ))}
      </ul>
      <ul x-ref="logos" class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
        {cardData.map((item) => (
          <li className="py-2">
            <img src={item.icon} className="h-8 lg:h-12" alt="Facebook" />
          </li>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default CompanyCarousel;
