import React from "react";
import { FaStar, FaStarHalf } from "react-icons/fa";

const RatingStars = ({ rating }) => {
  return (
    <div>
      {rating === 1 && <FaStar color="yellow" />}
      {rating === 2 && (
        <div className="flex gap-x-1">
          <FaStar color="orange" /> <FaStar color="orange" />
        </div>
      )}
      {rating === 3 && (
        <div className="flex gap-x-1">
          <FaStar color="orange" /> <FaStar color="orange" />
          <FaStar color="orange" />
        </div>
      )}
      {rating === 3 && (
        <div className="flex gap-x-1">
          <FaStar color="orange" /> <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
        </div>
      )}
      {rating < 3 && rating > 4 && (
        <div className="flex gap-x-1">
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStarHalf color="orange" />
        </div>
      )}
      {rating === 4 && (
        <div className="flex gap-x-1">
          <FaStar color="orange" /> <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
        </div>
      )}
      {rating > 4 && rating < 5 && (
        <div className="flex gap-x-1">
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStarHalf color="orange" />
        </div>
      )}
      {rating === 5 && (
        <div className="flex gap-x-1">
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
          <FaStar color="orange" />
        </div>
      )}
    </div>
  );
};

export default RatingStars;
