import React, { useEffect } from "react";
import {
  FaUserGraduate,
  FaUsers,
  FaCalendarAlt,
  FaRupeeSign,
} from "react-icons/fa";
import "../assets/css/IconCss.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { RiVideoOnLine } from "react-icons/ri";
import { BiSupport } from "react-icons/bi";

export default function KeyPrivileges() {
  useEffect(() => {
    Aos.init({
      duration: 500,
      easing: "ease-in-out",
      once: false, // Allow animations to repeat
      mirror: true, // Whether elements should animate out while scrolling past them
    });
  }, []);
  const privileges = [
    { name: "Special Mentorship Program", icon: <FaUserGraduate size={30} /> },
    { name: "Live Trading Support", icon: <BiSupport size={30} /> },
    { name: "One to One Assistance", icon: <FaUsers size={30} /> },
    { name: "online Classes", icon: <RiVideoOnLine size={30} />   },
    { name: "Lifetime free Assistance", icon: <FaCalendarAlt size={30} /> },
    { name: "High Probability setup", icon: <FaRupeeSign size={30} />   },
  ];

  return (
    <section style={{ background: "chocolate" }} className="w-screen py-5">
      <div className="flex flex-col  items-center gap-8  lg:py-10 bg-transparent h-fit">
        <h1 className="text-white -translate-x-0 lg:-translate-x-10 font-bold text-4xl">
          KEY PRIVILAGES
        </h1>
        <div className="lg:flex grid grid-cols-1 sm:grid sm:grid-cols-2 lg:flex-row justify-around text-white w-full">
          {privileges.map((item, index) => (
            <div key={index} className="flex flex-col items-center">
              <div  data-aos="flip-right" className="hexagon">
                <div className="hexagon-icon">{item.icon}</div>
              </div>
              <div  data-aos="fade-in" className="hexagon-text">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

// import React from 'react'

// export default function KeyPrivileges() {
//     return (
//         <section style={{ background: "url('https://e1.pxfuel.com/desktop-wallpaper/35/82/desktop-wallpaper-office-worker-office-work-thumbnail.jpg')", backgroundSize: "cover" }} className='w-screen'>
//             <div className='flex flex-col  items-center gap-8 px-2 lg:py-20 bg-transparent h-fit'>
//                 <h1 id='form-title' className='text-white font-semibold text-3xl'>Key Privileges</h1>
//                 <div className='flex flex-col sm:flex-col lg:flex-row  justify-around text-white w-full'>
//                 <div className=''>
//                     <strong>Mentorship Program</strong>
//                 </div>
//                 <div>
//                     <strong>Recording for Revision</strong>
//                 </div>
//                 <div>
//                     <strong>Student Community</strong>
//                 </div>
//                 <div>
//                     <strong>Every Sunday Stock Screening</strong>
//                 </div>
//                 <div>
//                     <strong>Money Back Guarantee</strong>
//                 </div>
//                 </div>
//             </div>
//         </section>
//     )
// }
