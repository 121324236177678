import React from "react";
import AboutImage from "../../assets/img/img_1.jpg";
import AboutImage1 from "../../assets/img/img_2.jpg";
import AboutImage2 from "../../assets/img/img_3.jpg";
import AboutCarousel from "../carousel/AboutCarousel";
function AboutSection2() {
  return (
    <>
      <section className="more_about_section">
        <div className="container">
          <div className="more_about_section_inner">
            <div className="row justify-around">
              <div className="col-md-4 lg:block sm:hidden hidden">
                <div className="more_about_section_left">
                  <div className="grid grid-cols-2 gap-3">
                  <figure className="col-span-2">
                    <img
                    data-aos="fade-up"
                      className="rounded-xl"
                      data-src=""
                      alt="img"
                      src={AboutImage}
                    />
                  </figure>
                  <figure>
                    <img
                    data-aos="flip-right"
                      className="rounded-xl"
                      data-src=""
                      alt="img"
                      src={AboutImage1}
                    />
                  </figure>
                  <figure>
                    <img
                    data-aos="flip-left"
                      className="rounded-xl"
                      data-src=""
                      alt="img"
                      src={AboutImage2}
                    />
                  </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="more_about_section_right">
                  <div className="heading_section">
                    <h1  data-aos="fade-up" className="text-white h1 mb-4 cinzel">MORE ABOUT US</h1>
                    {/* // =============about carousel=============== */}
                    <div>
                      <AboutCarousel />
                    </div>
                  </div>
                  <h2 className="h2"  data-aos="fade-right">
                    Since 2020, we helped people earn high incomes by investing
                    in the stock market
                  </h2>
                  <p data-aos="fade-left" className="text-3xl font-bold" style={{textAlign:"justify",fontWeight:500}}>
                    Welcome to Mindbulls Trading Institute, where expertise
                    meets innovation in the world of financial trading
                    education. Established in 2020, our mission is to empower
                    individuals with the knowledge and skills to navigate the
                    complexities of global markets. We offer comprehensive
                    training programs designed for all levels of traders, from
                    beginners to seasoned professionals.
                  </p>
                  <p data-aos="fade-left" className="text-3xl font-bold" style={{textAlign:"justify",fontWeight:500}}>
                    We are passionate about swing trading techniques, chart
                    reading techniques, and short term trading techniques that
                    attract powerful earnings in the stock market. The institute
                    offers offline/online stock market classes in Indore and
                    share market courses to assist individuals step forward with
                    a positive attitude in the finance platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutSection2;
