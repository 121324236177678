import React from "react";
import { RxCrossCircled } from "react-icons/rx";

const ModalComponent = ({ children, setOpen }) => {
  return (
    <>
      <div
        onClick={() => {
          setOpen(false);
        }}
        className="h-screen w-screen z-[70] flex items-center justify-center fixed backdrop-blur-sm bg-[#868686b9]"
      ></div>
      <div className="z-[80] fixed left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 rounded-2xl mx-auto  my-auto">
        <span
          onClick={()=>{setOpen(false)}}
          className="absolute top-2 w-10 z-[444] right-1 h-10"
        >
          <RxCrossCircled color="red" className="hover:text-red-600 hover:cursor-pointer"  fontWeight={800} size={32} />
        </span>
        {children}
      </div>
    </>
  );
};

export default ModalComponent;
