import React, { useState } from "react";
import Image from "../assets/icon/login-in.jpg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { SiMaildotru } from "react-icons/si";
import axiosClient from "../webServices/webUrls";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // fetch api
  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please enter both email and password.");
      return;
    }

    try {
      const res = await toast.promise(
        axiosClient.post("/admin/login", {
          email,
          password,
        }),
        {
          loading: "Logging in...",
          success: (res) => {
            if (!res.data.status) {
              throw new Error("login failed")
            }
            const token = res?.data?.data?.token;
            Cookies.set("token", token);
            Cookies.set("admin", "mbadmin24");
            navigate("/dashboard");
            return "Login successful";
          },
          error: "Login failed. Please try again.",
        }
      );
    } catch (error) {
      toast.error("Please try again");
      console.log(error.message);
    }
  };

  return (
    <section className="relative bg-gray-100 flex lg:flex-row flex-col-reverse h-screen lg:h-screen lg:items-center">
      <div className="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
        <div className="mx-auto flex flex-col items-center justify-center max-w-lg text-center">
          <div className="h-full mb-10 bg-blue-950 py-3 w-fit justify-center rounded-lg flex items-center gap-2">
            <img
              className="w-[20%] lg:w-[50%] max-w-[80px] lg:max-w-[50px]"
              src="https://mindbulls.com/static/media/bulls-white.6bc8e52f157badd3c97d.png"
              alt=""
            />
            <img
              className="w-[25%] lg:w-[50%] max-w-[200px] lg:max-w-[150px]"
              src="https://mindbulls.com/static/media/logo-label-white.f8092bd322d1f60c3598.png"
              alt=""
            />
          </div>
          <h1 className="text-2xl font-bold sm:text-3xl">Please login!</h1>

          <p className="mt-4 text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et libero
            nulla eaque error neque ipsa culpa autem, at itaque nostrum!
          </p>
        </div>

        <form
          onSubmit={handleLogin}
          className="mx-auto mb-0 mt-8 max-w-md space-y-4"
        >
          <div>
            <label htmlFor="email" className="sr-only">
              Email
            </label>

            <div className="relative">
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                placeholder="Enter email"
              />

              <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                <SiMaildotru />
              </span>
            </div>
          </div>

          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                placeholder="Enter password"
              />

              <span
                className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-end">
            <button
              type="submit"
              className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>

      <div className="relative h-1/2 w-full sm:h-1/2 lg:h-full lg:w-1/2">
        <img
          alt=""
          src={Image}
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </section>
  );
};

export default Login;
