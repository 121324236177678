import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../webServices/webUrls";
import { BiSearch } from "react-icons/bi";

const Table = () => {
  const [enquiry, setEnquiry] = useState([]);
  const [defaultData, setDefault] = useState([]);
  const [startPage, setStartPage] = useState(0);
  let itemsPerPage = 7;
  let totalPage = enquiry?.length / itemsPerPage;
  function filterEnquiry(e) {
    let searchValue = e.target.value;
    let filteredEnquiry = enquiry.filter(
      (item) =>
        (item.name && item.name.includes(searchValue)) ||
        (item.mobile && item.mobile.includes(searchValue)) ||
        (item.city && item.city.includes(searchValue))
    );
    setEnquiry(filteredEnquiry);
    console.log(filteredEnquiry);
  }

  const handlePrev = () => {
    if (startPage != 0) {
      setStartPage(startPage - itemsPerPage);
    }
  };
  const handleNext = () => {
    if (startPage + itemsPerPage < enquiry.length) {
      setStartPage(startPage + itemsPerPage);
    }
  };

  useEffect(() => {
    async function getEnquiry() {
      try {
        let API = await axiosClient.get("/admin/enquiries");
        setEnquiry(API.data.data);
        setDefault(API.data.data);
      } catch (error) {
        alert(error.message);
      }
    }
    getEnquiry();
  }, []);

  return (
    <>
      {/* component */}
      <div className="mt-5 bg-[#1f2937]  py-2 px-1">
        <h1 className="font-bold uppercase tracking-wider text-white text-2xl text-center ">
          Enquiries
        </h1>
        <div className="flex flex-col ml-4">
          <div className="flex gap-1 items-center rounded-md bg-white pl-2 w-fit">
            <BiSearch className="absolute z-0" color="grey" />
            <input
              onInput={(e) => {
                e.target.value.length < 3
                  ? setEnquiry(defaultData)
                  : filterEnquiry(e);
              }}
              className="w-60 pl-4 bg-transparent outline-none border-none p-1 rounded-md "
              type="text"
              placeholder="Search Name , number or city"
            />
          </div>
        </div>
      </div>
      <div className="overflow-x-scroll h-[80vh] rounded-lg border border-gray-200 shadow-md lg:m-5 xl:m-5 m-0">
        <table className="w-full overflow-x-scroll border-collapse bg-white text-left text-sm text-gray-500">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                S.No.
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Name
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Number
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Course
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                City
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Message
              </th>
              <th
                scope="col"
                className="px-6 w-1/5 py-4 font-medium text-gray-900"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 border-t border-gray-100">
            {enquiry?.slice(startPage, startPage + 7).map((enquirer, index) => (
              <tr className="hover:bg-gray-50">
                <td className="px-6 py-4">{startPage + index + 1}</td>
                <td className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                  <div className="text-sm">
                    <div className="font-medium capitalize text-gray-700">
                      {enquirer.name}
                    </div>
                    <div className="text-gray-400">{enquirer.email}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                    {enquirer.mobile}
                  </span>
                </td>
                <td className="px-6 py-4">{enquirer.course}</td>
                <td className="px-6 py-4">
                  <div className="flex gap-2">
                    <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                      {enquirer.city}
                    </span>
                  </div>
                </td>
                <td className="px-6 w-72 py-4">
                  {enquirer.message ? enquirer.message : "null"}
                </td>
                <td className="px-6 py-4">
                  {new Date(enquirer.createdAt).toDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex flex-row justify-evenly mt-4">
          <button
            onClick={handlePrev}
            className="bg-[#1f2937] text-white px-4 py-1 rounded-xl capitalize text-xl"
          >
            prev
          </button>
          <button
            onClick={handleNext}
            className="bg-[#1f2937] text-white px-4 py-1 rounded-xl capitalize text-xl"
          >
            next
          </button>
        </div>
      </div>
    </>
  );
};

export default Table;
