import React from "react";
import AboutSection2 from "../components/aboutComponent/Section2";
import AboutSection3 from "../components/aboutComponent/Section3";
import AboutSection4 from "../components/aboutComponent/Section4";
import MainSection from "../components/aboutComponent/section1";
const AboutPage = () => {
  return (
    <>
      <MainSection />
      <AboutSection2 />
      <AboutSection3 />
      <AboutSection4 />
    </>
  );
};

export default AboutPage;
