import React from "react";
import chandanSir from "../../assets/img/img_2.jpg"
import GauravSirsiya from "../../assets/img/img_3.jpg"


function AboutSection4() {

  return (
    <>
      <section className="about_story">
        <div className="container">
          <div className="heading_section">
            <h2  data-aos="zoom-in">
              <span>Our Vision</span>
            </h2>
            <p  data-aos="fade-left">
              At Mindbulls Trading Institute, our vision is to create a
              community of well-informed and skilled traders who can confidently
              make informed decisions in the stock market. We aim to demystify
              the world of financial trading and make it accessible to everyone,
              regardless of their background or experience level.
            </p>
          </div>
          <div  data-aos="zoom-in" className="heading_section">
          <h2>
              <span>Expert Mentors</span>
            </h2>
          </div>
          <div className="about_story_inner">
            <ul className="our_story_content">
              <li className="d-flex">
                <div  data-aos="fade-left" className="about_story_inner_left">
                  <h2>Mr. Chandan Urdheya </h2>
                  <p>
                  NISM certified with 7 years of experience
                  </p>
                </div>
                <div  data-aos="zoom-out" className="about_story_inner_right">
                  <figure>
                    <img
                      className=""
                      alt="img"
                      src={chandanSir}
                    />
                  </figure>
                </div>
              </li>
              <li className="d-flex">
                <div data-aos="fade-right" className="about_story_inner_left">
                  <h2>Mr. Gaurav Sirsiya</h2>
                  <p>
                  Mr. Gaurav Sirsiya have 5 years experience in Indian stock market.
                  </p>
                </div>
                <div  data-aos="zoom-out" className="about_story_inner_right">
                  <figure>
                    <img
                      className=""
                      alt="img"
                      src={GauravSirsiya}
                    />
                  </figure>
                </div>
              </li>
              {/* <li className="d-flex">
                <div className="about_story_inner_left">
                  <h2>2019</h2>
                  <p>
                    As our customers developed various stock market services, we
                    started offering the same. Our office expanded to 11 team
                    members. We made our transition from being a stock market
                    education provider to a dedicated team model. We reinvented
                    ourselves.
                  </p>
                </div>
                <div className="about_story_inner_right">
                  <figure>
                    <img
                      className=""
                      data-src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/head-third.webp"
                      alt="img"
                      src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/head-third.webp"
                    />
                  </figure>
                </div>
              </li>
              <li className="d-flex">
                <div className="about_story_inner_left">
                  <h2>2021</h2>
                  <p>
                    Today with the collabarative efforts and hard work of Team
                    GTF. We have our Personal Workplace and expanding our vision
                    to make independent traders all around the globe.
                  </p>
                </div>
                <div className="about_story_inner_right">
                  <figure>
                    <img
                      className=""
                      data-src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/head-four.webp"
                      alt="img"
                      src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/head-four.webp"
                    />
                  </figure>
                </div>
              </li>
              <li className="d-flex">
                <div className="about_story_inner_left">
                  <h2>2023</h2>
                  <p>
                    Today GTF is one of the leading Stock market institutes in
                    India with more than 2.5 lac GTF traders around the globe.
                    We are highly thankful to all for making our mission
                    successful <strong>#HarGharGTFtrader</strong>
                  </p>
                </div>
                <strong>
                  <div className="about_story_inner_right">
                    <figure>
                      <img
                        className=""
                        data-src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/head-five.webp"
                        alt="img"
                        src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/head-five.webp"
                      />
                    </figure>
                  </div>
                </strong>
              </li> */}
              <strong></strong>
            </ul>
            <strong>
              <div className="vecor_about_story">
                <span className="vecor_about_story_first">
                  <img
                    className=""
                    data-src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/about_green.webp"
                    alt="img"
                    src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/about_green.webp"
                  />
                </span>
                <span className="vecor_about_story_second">
                  <img
                    className=""
                    data-src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/duble_shap.webp"
                    alt="img"
                    src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/duble_shap.webp"
                  />
                </span>
              </div>
            </strong>
          </div>
          <strong></strong>
        </div>
        <strong></strong>
      </section>
    </>
  );
}

export default AboutSection4;
