import React from "react";
import InfoSection from "../components/InfoSection";
import FormSection from "../components/FormSection";
import SocialmediaSection from "../components/SocialmediaSection";
import Breadcrumb from "../components/breadcrumb";

const ContactusPage = () => {
  return (
    <div>
      <div className="w-screen h-[35vh] mt-20 gap-3 px-2  flex flex-col items-center justify-center bg-blue-900 text-white">
        <h1 className="text-center font-bold lg:text-4xl text-2xl  ">
          Contact Us
        </h1>
        <p className="text-center text-lg font-normal">
        Feel free to Contact us at any time . we will get back to you as soon as possible.
        </p>
      </div>
      <FormSection />
      <div className="contact_tab">
        <div className="container">
          <div className="tab_list_warp">
            <p>
              <a href="#">Home</a>
              <i className="fa-solid fa-angle-right" />
              Contact Us
            </p>
          </div>
      {/* =================map section start=========================== */}
          <div className="contact_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.1440993849415!2d75.8571016747604!3d22.68567902881743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcf129b05143%3A0xd7db2f7d4a9e63cf!2sSai%20Ram%20Plaza!5e0!3m2!1sen!2sin!4v1721206523067!5m2!1sen!2sin"
              width="100%"
              height={450}
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactusPage;
