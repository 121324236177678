import React, { useEffect } from "react";
import RGBdots from "../../assets/icon/dotIcons.png";
import graphHolo from "../../assets/graphHolo2.jpg";
import logo from "../../assets/bulls-white.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import Notiflix from "notiflix";
import Aos from "aos";
import "aos/dist/aos.css";
import axiosClient from "../../webServices/webUrls";

const EnquiryForm = ({ setOpen }) => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false, // Allow animations to repeat
      mirror: true, // Whether elements should animate out while scrolling past them
    });
  }, []);
  const { register, handleSubmit, reset } = useForm();
  const submitEnquiry = async (data) => {
    Notiflix.Loading.hourglass();
    try {
      const res = await axiosClient.post(
        "/add/enquiry/add",
        data
      );
      if (res.status !== 200 || res?.data?.status === false) {
        throw new Error("failed to send Enquiry , Try Again");
      }

        Notiflix.Loading.remove();
        Notiflix.Notify.success(res?.data?.msg);
        setOpen(false);
      reset();
    } catch (error) {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error.message);
      }, 1000);
    }
  };

  useEffect(() => {
    Notiflix.Notify.init({
      position: "center-bottom",
    });
    Notiflix.Loading.init({
      svgColor: "orange",
    });
  }, []);
  return (
    <div
      data-aos="fade-up"
      className="flex flex-col justify-between items-center lg:px-2 pb-4 w-[95vw] lg:w-[70vw] rounded bg-black "
    >
      <span className="relative top-0 rounded self-start w-10 left-2 h-10">
        <img src={RGBdots} alt="rgb-dots" />{" "}
      </span>
      <div className="rounded w-full ">
        <img src={logo} width={50} className="mx-auto" alt="logo" />
        <h1 className="cinzel text-2xl text-center font-bold text-[#d49116]">
          Enquiry Form
        </h1>
        <p className="text-center  text-white">
          Fill out Enquiry Form to get a callback !
        </p>
        <div id="form-container" className="flex items-start mt-4">
          <img
            className="rounded w-[48%] h-auto hidden lg:inline md:inline brightness-50"
            src={graphHolo}
          />
          <div className="w-full px-6">
            <form
              className="grid grid-cols-2 gap-x-4 p-2 gap-y-2 "
              onSubmit={handleSubmit(submitEnquiry)}
            >
              <div className="flex flex-col col-span-2 gap-2">
                <label className="text-xl gruppo font-bold text-white ">
                  *Full Name
                </label>
                <input
                  className="px-2 h-9 font-medium text-md rounded border-none focus:border-none focus:outline-none"
                  type="text"
                  required
                  {...register("name", { required: "email is required" })}
                  placeholder="Enter Your Name"
                />
              </div>
              <div className="flex  lg:col-span-1 flex-col gap-2">
                <label className="text-xl text-white gruppo font-bold">
                  *E-mail
                </label>
                <input
                  className="px-2 h-9  font-medium text-md rounded border-none focus:border-none focus:outline-none"
                  type="text"
                  required
                  {...register("email", { require: "email is require" })}
                  placeholder="Enter Your Email Id"
                />
              </div>
              <div className="flex  lg:col-span-1 flex-col gap-2">
                <label className="text-xl text-white gruppo font-bold">
                  *Contact No
                </label>
                <input
                  className="px-2 h-9  font-medium text-md rounded border-none focus:border-none focus:outline-none"
                  type="text"
                  required
                  {...register("mobile", {
                    require: "contact is require",
                    maxLength: 10,
                  })}
                  maxLength={10}
                  placeholder="Enter Your Mobile Number"
                />
              </div>
              <div className="flex  lg:col-span-1 flex-col gap-2">
                <label className="text-xl text-white gruppo font-bold">
                  City{" "}
                </label>
                <select
                  {...register("city")}
                  required
                  className="px-2 h-9  font-medium text-md rounded border-none focus:border-none focus:outline-none"
                >
                  <option>--Select City--</option>
                  <option value="Other">Other</option>
                  <option value="Indore">Indore</option>
                  <option value="Ujjain">Ujjain</option>
                  <option value="Bhopal">Bhopal</option>
                  <option value="Dewas">Dewas</option>
                  <option value="Khargone">Khargone</option>
                  <option value="Barwaha">Barwaha</option>
                  <option value="Dhar">Dhar</option>
                  <option value="Dhamnod">Dhamnod</option>
                  <option value="Maheshwar">Maheshwar</option>
                  <option value="Sanwer">Sanwer</option>
                  <option value="Depalpur">Depalpur</option>
                  <option value="Hatod">Hatod</option>
                  <option value="Betma">Betma</option>
                  <option value="Manpur">Manpur</option>
                  <option value="Sanawad">Sanawad</option>
                  <option value="Vadodara">Vadodara</option>
                  <option value="Jabalpur">Jabalpur</option>
                  <option value="Sagar">Sagar</option>
                  <option value="Balaghat">Balaghat</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Pune">Pune</option>
                </select>
              </div>
              <div className="flex  lg:col-span-1 flex-col gap-2">
                <label className="text-xl text-white gruppo font-bold">
                  Courses
                </label>
                <select
                  {...register("course")}
                  className="px-2 h-9  font-medium text-md rounded border-none focus:border-none focus:outline-none"
                >
                  <option>--Select Course--</option>
                  <option value={"Basic Course"}>Basic Course</option>
                  <option value={"Advance Course"}>Advance Course</option>
                  <option value={"Cerification and Other"}>
                    Cerification and Other
                  </option>
                  <option value={"Option Trading"}>Option Trading</option>
                </select>
              </div>
              <div className="flex col-span-2 lg:col-span-2 flex-col gap-2">
                <label className="text-xl  text-white gruppo font-bold">
                  Message
                </label>
                <textarea className="p-2" {...register("message")} />
              </div>
             
              <button className="bg-orange-600 px-1 w-[50%] mt-3 cursor-pointer hover:shadow-lg glowEffect duration-200 py-2 rounded-lg text-white ">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
