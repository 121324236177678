import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay, Pagination } from "swiper/modules";
import Img1 from "../../assets/img/img_1.jpg";
import Img2 from "../../assets/img/img_2.jpg";
import Img3 from "../../assets/img/img_3.jpg";
import Img4 from "../../assets/img/img_4.jpg";


const AboutCarousel = () => {
  return (
    <div className=" lg:hidden sm:block flex">
      <Swiper
    className="mySwiper"
          slidesPerView={1}
          spaceBetween={32}
          modules={[Autoplay, Pagination]}
          loop={true}
          centeredSlides={true}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 32,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
      >
        <SwiperSlide >
          <img src={Img1} alt="img1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Img2} alt="img2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Img3} alt="img3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Img4} alt="img4" />
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default AboutCarousel;
