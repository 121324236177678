import React, { useEffect } from "react";
import backGround from "../assets/Background.jpg";
import ContactForm from "../components/contactForm";
import KeyPrivileges from "../components/keyPrivileges";
import CardComponent from "../components/cards/cards";
import InfoCards from "../components/cards/InfoCards";
import chart from "../assets/chart.gif";
import bullAssetImg from "../assets/icon/5.png";
import arrowChart from "../assets/icon/6.png";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
import TestimonialCarousel from "../components/carousel/TestimonialsCarousel";
import { coursesData } from "../data/courseData";
import CompanyCarousel from "../components/carousel/companyCarousel";
import { cardData } from "../data/courseData";
import { Link } from "react-router-dom";
import vd from "../assets/icon/vd.mp4";
import Aos from "aos";
import "aos/dist/aos.css";
import TypedText from "../components/uiComponents/TypedText";
const HomePage = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
      easing: "ease-in-out",
      once: false, // Allow animations to repeat
      mirror: true, // Whether elements should animate out while scrolling past them
    });
  }, []);
  return (
    <>
      {" "}
      <section
        id="landing_background"
        className="h-screen overflow-hidden w-screen landing-background "
      >
        {/* ==================== mask for background ===================== */}
        <div className="w-full flex flex-col justify-center items-start h-screen bg-transparent backdrop-brightness-[0.4]"></div>
        <div className="w-full pt-20 flex flex-col pl-2 lg:pl-8 xl:lg:pl-8 md:pl-6 justify-center items-start h-full bg-transparent backdrop-blur-[4px] gap-2 absolute top-0 z-10">
          <div>
            <img
              data-aos="fade-right"
              className="lg:inline rounded-xl hidden w-80"
              src={chart}
              alt="chart"
            />
            <img
              data-aos="fade-right"
              className="lg:inline rounded-xl  hidden ml-2 h-44"
              src={arrowChart}
              alt="arrowchartIcon"
            />
          </div>
          <h1 className="text-3xl font-bold text-white cinzel uppercase text-center lg:hidden md:hidden xl:hidden sm:block block">
            Mindbull Trading Institute
          </h1>
          <video autoPlay loop muted className="w-full h-auto lg:hidden">
            <source src={vd} type="video/mp4" />
          </video>
          <div id="info_section" className="px-3">
            <h1
              data-aos="fade-up"
              className="text-3xl md:text-3xl lg:block xl:block sm:hidden hidden md:hidden lg:text-4xl xl:text-5xl font-bold mt-6 text-white "
            >
              {" "}
              <span className=" text-orange-500">Mindbulls</span> Trading
              Institute
            </h1>

            <h1
              data-aos="fade-right"
              className=" text-2xl lg:text-3xl xl:text-3xl md:text-3xl text-white w-full font-bold "
            >
              Ensure Your Financial Freedom with
            </h1>
            <TypedText />

            <h2
              data-aos="fade-up"
              className="border-t-2 border-b-2 border-white py-2 text-md font-semibold text-orange-500 mt-9 "
            >
              From Basics to Brilliance in Stock Market
            </h2>
            <Link to="/courses">
              {" "}
              <button className="mt-7 hover:translate-x-2 bg-orange-400 flex cursor-pointer glowEffect duration-300 capitalize border border-1 border-gray-300 rounded-md py-2 px-5 text-white font-semibold items-center gap-4">
                Explore Courses
              </button>
            </Link>
          </div>
          <img
            data-aos="fade-left"
            className="lg:inline right-0 absolute top-16 animate_moveSidewise rounded-xl hidden scale-90"
            src={bullAssetImg}
            alt="asset2"
          />
        </div>
      </section>
      <CompanyCarousel />
      <section className=" text-white bg-[#090723] pt-4 pb-16 h-fit">
        <h1
          data-aos="fade-up"
          className="mb-8 text-4xl  font-bold text-center py-8 section_headings"
        >
          What we Do?
        </h1>
        <div className="grid gap-y-16 gap-x-2 lg:grid-cols-3 sm:grid-col-2   grid-col-1  text-center">
          {cardData.map((item, index) => (
            <div data-aos="fade-up">
              {" "}
              <InfoCards
                icon={item.icon}
                description={item.description}
                heading={item.heading}
              />
            </div>
          ))}
        </div>
      </section>
      <KeyPrivileges />
      <TestimonialCarousel />
      <div className="bg-[#090723] w-full h-fit flex pb-6 flex-col items-center ">
        <h1 className="section_headings text-white text-center lg:text-5xl md:text-2xl text-3xl font-semibold mt-8 mb-6">
          {" "}
          OUR FEATURED COURSES
        </h1>
        <div className="grid lg:grid-cols-3  sm:grid-col-2  grid-col-1 h-4/5 items-center justify-center gap-5">
          {coursesData.map((item) => (
            <CardComponent
              thumbnail={item.image}
              courseDescription={item.description}
              courseName={item.courseName}
            />
          ))}
        </div>
      </div>
      <ContactForm />
    </>
  );
};

export default HomePage;
