import Foundation from "../assets/icon/foundationjpg.jpg";
import cryptoIcon from "../assets/icon/cryto.jpg";
import forexIcon from "../assets/icon/forex.jpg";
import certificationIcon from "../assets/icon/cert2.jpg";
import advanceIcon from "../assets/icon/ad.jpg";
import FeturedCourse1 from "../assets/icon/StockMarketTrading.jpg"
import FeturedCourse2 from "../assets/icon/fundamentalAnalysis.jpg"
import FeturedCourse3 from "../assets/icon/advance-course.jpg"
export const coursesData = [
  {
    courseName: "Stock Market Foundation",
    image:FeturedCourse1,
    description:
      "The Stock Market Foundation Course offers a comprehensive overview of the stock market, including the basics of how it operates, different types of stocks, and major exchanges. It covers technical analysis, teaching you to evaluate securities through chart patterns, indicators, and statistical trends, as well as fundamental analysis to assess a company's value by examining financial statements and industry conditions. The course also delves into derivative market analysis, explaining options and futures for hedging and speculation, and includes essential risk management strategies to protect your investments. By completing this course, you'll gain the knowledge and skills needed to navigate the stock market effectively and make informed investment decisions.",
  },
  {
    courseName: "Advance Stock Market Course",
    image:FeturedCourse2,
    description:
      "This advanced stock market course covers essential topics to enhance your trading and investment skills. It begins with the basics of the stock market and progresses to technical and fundamental analysis. You'll delve into derivative market analysis and equity trading, learning about various trading setups and strategies. The course also includes a section on option selling and emphasizes the importance of risk management in trading. Overall, it provides a comprehensive understanding of the stock market, equipping you with practical skills for informed decision-making.",
  },
  {
    courseName: "Other Courses",
    image:FeturedCourse3,
    description:
      "These courses offer specialized training in different areas of financial markets. The NISM Certification Training prepares you for the National Institute of Securities Markets (NISM) certification exams, covering regulatory frameworks, securities markets operations, and financial products in India. The Forex Trading course focuses on the foreign exchange market, teaching you how to trade currencies, understand market dynamics, analyze economic indicators, and implement effective trading strategies. Lastly, the Crypto Trading course introduces you to the world of cryptocurrencies, covering blockchain technology, market analysis, trading strategies, and risk management specific to digital assets. Together, these courses provide comprehensive knowledge and practical skills for navigating various financial markets.",
  },
  // {
  //   courseName: "Options Trading Strategies",
  //   image:
  //     "https://covers.storytel.com/jpg-640/9781667045450.413636f8-facb-4b2e-ab6e-5ae0666fd65a?optimize=high&quality=70",
  //   description:
  //     "Explore various options trading strategies to maximize profits and minimize risks.",
  // },
  // {
  //   courseName: "Day Trading Techniques",
  //   image:
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7nuwrkHtHZEmHhR4rEutmF5dFrWqKhVsyqw&s",
  //   description:
  //     "Master the art of day trading with strategies focused on short-term gains and quick decision-making.",
  // },
  // {
  //   courseName: "Portfolio Management",
  //   image:
  //     "https://www.indiabonds.com/news-and-insight/wp-content/uploads/2022/12/Porfolio-Management.jpg",
  //   description:
  //     "Learn how to build and manage a diversified investment portfolio for long-term success.",
  // },
];

export const cardData = [
  {
    icon: "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/icons/graph-up.svg",
    heading: "Market Analysis",
    description: "In-depth analysis of market trends and movements.",
  },
  {
    icon: "https://cdn4.iconfinder.com/data/icons/aiga-symbol-signs/441/aiga_cashier-256.png",
    heading: "Investment Opportunities",
    description: "Explore lucrative investment opportunities.",
  },
  {
    icon: "https://cdn0.iconfinder.com/data/icons/stock-market-pack/128/A-14-64.png",
    heading: "Stock Performance",
    description: "Track and analyze stock performance over time.",
  },
  {
    icon: "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/icons/calendar3.svg",
    heading: "Trading Calendar",
    description: "Stay updated with the latest trading schedules.",
  },
  {
    icon: "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/icons/file-earmark-bar-graph.svg",
    heading: "Financial Reports",
    description: "Access detailed financial reports and data.",
  },
];

export const coursesDetials = [
  {
    name: "Stock Market Foundation ",
    Img: Foundation,
    alt: "Foundation-course",
    topics:["Basic of stock market","Techinical Analysis","Fundamental Analysis","Risk Management"]
  },
  {
    name: "Advance Stock Market ",
    Img: advanceIcon,
    alt: "Advance-course",
    topics:["Advance Technical Analysis","Advance Fundamental Analysis","Derivative market analysis","Option selling"]
  },
  {
    name: "Forex Trading",
    Img: forexIcon,
    alt: "forex-trading-course",
    topics:["Basic of Forex","Technical Analysis","Fundamental Analysis","Risk Management"]
  },
  {
    name: "Crypto Trading",
    Img: cryptoIcon,
    alt: "Foundation-course",
    topics:["Basic of Crypto","Technical Analysis","Fundamental Analysis","Risk Management"]
  },
  {
    name: "Certification and other",
    Img: certificationIcon,
    alt: "Foundation-course",
    topics:["NISM Certification","NISM Certification","NISM Certification","NISM Certification"]
  },
];
