import React, { useEffect } from "react";
import "../../assets/css/card.css";
import { Link } from "react-router-dom";
import { FaBook } from "react-icons/fa";
import Aos from "aos";
const CardComponent = ({ thumbnail, courseDescription, courseName }) => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      easing: "ease-in-out",
      once: false, // Allow animations to repeat
      mirror: true, // Whether elements should animate out while scrolling past them
    });
  }, []);
  return (
    <div
      data-aos="flip-right"
      className="max-w-sm shadow-lg bg-white rounded-lg hover:scale-95 m-4"
    >
      <img
        data-aos="zoom-in"
        src={thumbnail}
        alt="Chart"
        className="w-full h-64 rounded-tl-lg rounded-tr-lg"
      />
      <div className="p-4">
        <div
          id="course_card_title"
          className="flex justify-between items-center mb-2"
        >
          <h3 data-aos="fade-right" className="text-lg font-semibold">
            {courseName}
          </h3>
          <button id="title_btn_design" className="text-xl">
            <FaBook color="white" />
          </button>
        </div>
        <p className="text-gray-900 text-sm mb-4 line-clamp-4 my-3">
          {courseDescription}
        </p>
        <Link
          data-aos="zoom-out"
          to="/courses"
          className="text-orange-500 mt-2 py-3 px-5 border-2 rounded-lg border-orange-400 font-bold hover:text-blue-700 transition-colors"
        >
          Explore More
        </Link>
      </div>
    </div>
  );
};

export default CardComponent;
