import React, { useEffect, useState } from "react";
import logo from "../../assets/bulls-white.png";
import logoLabel from "../../assets/logo-label-white.png";
import { Link } from "react-router-dom";
import { TbMenu2 } from "react-icons/tb";
import { RiHomeLine } from "react-icons/ri";
import RGBdots from "../../assets/icon/dotIcons.png";
import { RxCrossCircled } from "react-icons/rx";
import { FaRegBookmark } from "react-icons/fa";
import { RiBloggerLine } from "react-icons/ri";
import { AiOutlineStock } from "react-icons/ai";
import { BsInfoLg } from "react-icons/bs";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { navBarItems } from "../../data/NavBarData";
import ModalComponent from "../modal/modalComponent";
import EnquiryForm from "../uiComponents/EnquiryForm";

const NavBar = () => {
  const [activeTab, setActiveTab] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [open, setOpen] = useState(false);
  let role = "admin";

  const handleMenuOpen = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 8000);
  }, []);
  return (
    <>
      <header
        id="header"
        className="flex z-50 fixed top-0 w-full items-center bg-[#00000094] shadow-xl lg:justify-around md:justify-around justify-between backdrop-blur-sm h-20 px-4"
      >
        <Link to="/" className="h-full flex items-center gap-2">
          <img src={logo} className="h-[50%] mb-2 lg:navLogo" alt="logo" />
          <img src={logoLabel} className="h-[40%] lg:navLogo" alt="logo" />
        </Link>
        {/* ==================navbar desktop================ */}
        <nav className="  lg:block hidden md:block px-2 py-2">
          <ul className=" list-none flex items-center h-full gap-8 px-2  justify-between">
            {navBarItems.map((item) => (
              <li
                onClick={(e) => {
                  setActiveTab(item?.title);
                }}
                className={"nav-item cursor-pointer text-white font-semibold "}
              >
                <Link to={item.path}>{item.title}</Link>
              </li>
            ))}
            {role === "mbadmin" && (
              <li className="nav-item cursor-pointer text-white font-semibold ">
                Admin Dashboard
              </li>
            )}
          </ul>
        </nav>
        <div
          onClick={() => {
            setOpen(true);
          }}
          className=" glowEffect cursor-pointer lg:block hidden md:flex hover:shadow-ld shadow-orange-400 duration-300 capitalize border border-1 border-gray-300 rounded-md py-2 px-5 text-white font-semibold items-center gap-4"
        >
          book A Demo
        </div>
        <div
          onClick={handleMenuOpen}
          className="lg:hidden md:hidden block rounder"
        >
          <TbMenu2 color="white" size={30} />
        </div>
        {/* =============== mobile menu=============== */}
        <nav
          className={
            openMenu
              ? "absolute duration-300 lg:hidden md:hidden  z-40 top-0 left-0 w-screen h-screen pt-1  bg-[black] flex justify-start items-start flex-col"
              : "absolute lg:hidden md:hidden duration-300  z-40 top-0 left-0 w-screen h-screen pt-1  bg-[black] flex justify-start items-start flex-col translate-x-full"
          }
        >
          <div className="flex justify-between items-center px-2 w-[98%]">
            <span className="relative top-0 w-10 left-0 h-10">
              <img src={RGBdots} alt="rgb-dots" />{" "}
            </span>
            <span
              onClick={handleMenuOpen}
              className="relative top-3 w-10 right-1 h-10"
            >
              <RxCrossCircled color="white" fontWeight={800} size={35} />
            </span>
          </div>
          <ul className="w-4/5 flex mx-auto flex-col gap-2 items-center pt-16 ">
            <Link
              onClick={() => {
                setOpenMenu(false);
              }}
              className="mobile-nav-items  text-white"
              to={"/"}
            >
              <li className="flex gap-2  items-center">
                <span>
                  <RiHomeLine color="white" />
                </span>
                <span>Home</span>
              </li>
            </Link>
            <Link
              onClick={() => setOpenMenu(false)}
              className="mobile-nav-items  text-white"
              to={"/courses"}
            >
              <li className="flex gap-2  items-center">
                <span>
                  <FaRegBookmark color="dodgerblue" />
                </span>
                <span>Courses</span>
              </li>
            </Link>
            <Link
              to="/blogs"
              onClick={() => setOpenMenu(false)}
              className="mobile-nav-items  text-white"
            >
              <li className="flex gap-2  items-center">
                <span>
                  <RiBloggerLine color="red" />
                </span>
                <span>Blogs</span>
              </li>
            </Link>
            <a
              href="https://iserve-partners.indiainfoline.com/common/openaccount?E1Code=c6jPF7NK5GMc%209jSoBNwwQ%3D%3D&SourceChannelID=KBWtK3WkgyXc2SanaseYhg%3D%3"
              target="blank"
              onClick={() => setOpenMenu(false)}
              className="mobile-nav-items  text-white"
            >
              <li className="flex gap-2  items-center">
                <span>
                  <AiOutlineStock color="lightgreen" />
                </span>
                <span>Demat</span>
              </li>
            </a>
            <Link
              onClick={() => setOpenMenu(false)}
              className="mobile-nav-items  text-white"
              to={"/about"}
            >
              <li className="flex gap-2  items-center">
                <span>
                  <BsInfoLg color="yellow" />
                </span>
                <span>About Us</span>
              </li>
            </Link>
            <Link
              to={"/contact"}
              onClick={() => setOpenMenu(false)}
              className="mobile-nav-items  text-white"
            >
              <li className="flex gap-2  items-center">
                <span>
                  <MdOutlinePhoneInTalk color="orange" />
                </span>
                <span>Contact Us</span>
              </li>
            </Link>
            {role === "mbadmin" && (
              <Link
                onClick={() => setOpenMenu(false)}
                className="mobile-nav-items  text-white"
                to={"/about"}
              >
                <li className="flex gap-2  items-center">
                  <span>
                    <BsInfoLg color="yellow" />
                  </span>
                  <span>Admin Dashboard</span>
                </li>
              </Link>
            )}
          </ul>
        </nav>
      </header>
      {open === true && (
        <ModalComponent
          setOpen={(v) => {
            setOpen(v);
          }}
          children={
            <EnquiryForm
              setOpen={(v) => {
                setOpen(v);
              }}
            />
          }
        />
      )}
    </>
  );
};

export default NavBar;
