import React from "react";

const TermsAndConditionPage = () => {
  return (
    <>
      <div className="w-screen h-[35vh] mt-20 gap-3 px-2  flex flex-col items-center justify-center bg-blue-900 text-white">
        <h1 className="text-center font-bold lg:text-4xl text-2xl ">Teams & Policy</h1>
        <p className="text-center text-lg font-normal">
          Please read this agreement carefully, as it contains important
          information regarding your legal rights and remedies.
        </p>
      </div>
      {/* ========================== policies section =================== */}
      <div className="p-4 lg:w-4/5 w-11/12 mx-auto my-3 bg-slate-100">
        <h2 className="font-bold pt-4 text-2xl uppercase ">Privacy Policies</h2>
        <p className="text-xl">
          Your privacy is very important to us. Accordingly, we have developed
          this Policy to help you understand how we collect, use, communicate,
          disclose, and make use of personal information.
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">
          Personal Information
        </h2>
        <p className="text-xl">
          MindBull Trading Institute™ only collects the necessary personally
          identifiable information to respond to your requests for our services.
          Personally identifiable information includes, but is not limited to,
          first and last name, email address, phone number, profession, address,
          and any other information that itself identifies or, when tied to the
          above information, may identify you as a specific individual.
          <br />
          <br /> This website does not collect personally identifiable
          information about you except when you specifically and knowingly
          provide it.
          <br />
          <br /> By registering your mobile number on MindBull Trading
          Institute™ website, you agree to be contacted by our personnel or
          receive SMS messages related to our services even if the contact
          number you have entered is on DND (Do Not Disturb).
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">
          What type of information do we collect and how is it used?
        </h2>
        <p>
          The contact information we collect comes directly from you. The types
          of information we collect include the following:
        </p>
        <ul className=" list-disc list-inside">
          <li>First and Last Name</li>
          <li>
            Home or other Physical Addresses, including Street Name, Name of
            City or Town, Zip Code, and Country.
          </li>
          <li>Email address.</li>
          <li>Telephone or Cellphone Number.</li>
          <li>Information collected via “cookies.”</li>
        </ul>
        <h2 className="font-bold pt-4 text-2xl uppercase ">
          Purpose Of Information Collection
        </h2>
        <p>
          Collecting information about you is necessary for us to provide you
          with high-quality services. Purposes for collecting information
          generally include providing products or services requested, confirming
          your identity, protecting against fraud, or dealing with matters
          concerning the relationship between us. As there is a great risk
          associated with giving your personal information and we understand
          your concern, we assure you of the following points:
        </p>
        <ul className=" list-disc list-inside">
          <li>
            We only use your personal information to provide you the service at
            your account. The only use we will make of your information is for
            providing the services you have subscribed to, for which you
            provided us your information. We do not sell, rent, exchange,
            transfer, or give your information to any company or individual for
            any reason without your consent. We do not spam you.
          </li>
          <li>
            All information regarding Payment Gateway will be safely and
            securely handled on our site.
          </li>
          <li>
            In addition to the service provided to you, your information (mobile
            number, email ID, etc.) may be used for sending you newsletters,
            surveys, contest information, or information about any new services
            of the Company. By subscribing to our services, you consent to our
            Privacy Policy and Terms of Use.
          </li>
        </ul>
        <h2 className="font-bold pt-4 text-2xl uppercase ">COOKIES</h2>
        <p>
          A cookie is a string of information that a website stores on a
          visitor’s computer, and that the visitor’s browser provides to the
          website each time the visitor returns. MindBull Trading Institute™
          uses cookies to help identify and track visitors, their usage of
          MindBull Trading Institute™ website, and their website access
          preferences. Visitors who do not wish to have cookies placed on their
          computers should set their browsers to refuse cookies before using
          MindBull Trading Institute™ websites, with the drawback that certain
          features of MindBull Trading Institute™ websites may not function
          properly without the aid of cookies.
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase  ">PRIVACY POLICY REVISION</h2>
        <p>
          Although most changes are likely to be minor, MindBull Trading
          Institute™ may change its Privacy Policy from time to time, at its
          sole discretion. MindBull Trading Institute™ encourages visitors to
          frequently check this page for any changes to its Privacy Policy. Your
          continued use of this site after any change in this Privacy Policy
          will constitute your acceptance of such change.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditionPage;
