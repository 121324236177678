import React from "react";
import { CgMail } from "react-icons/cg";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import axiosClient from "../webServices/webUrls";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";

function ContactForm() {
  const { register, handleSubmit, reset } = useForm();
  const handleForm = async (data) => {
    try {
      console.log(data)
      const api = await axiosClient.post("add/enquiry/add",data);
      const res = await api.data;
      Notiflix.Notify.success("Your enquiry has been sent successfully ")
      console.log(res);
    } catch (err) {
      Notiflix.Notify.failure(err.message);
    }
  };
  return (
    <>
      <section
        className=" bg-center"
        style={{
          backgroundImage:
            "url('https://img.freepik.com/premium-photo/plant-pot-is-table_794493-1223.jpg?w=740')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="flex py-40 justify-around items-center w-full h-full flex-wrap brightness-low">
          <div
            id="contact-info-container "
            className="lg:w-[45%] xl:w-[45%] md:11/12 w-11/12 px-3"
          >
            <h1 className="font-4xl font-bold text-white capitalize text-2xl tracking-wide">
              Request For A Callback .
            </h1>
            <p className="text-white font-normal text-xl my-4">
              Feel free to Contact us at any time .<br /> we will get back to
              you as soon as possible.
            </p>
            <div className="flex gap-x-2 my-2 items-center">
              <FaLocationDot color="yellow" />
              <span className="text-white font-normal uppercase">
                {" "}
                M3 sairam plaza, mangal nagar near rajiv gandhi square indore
              </span>
            </div>
            <div className="flex gap-x-2 my-2 items-center">
              <FaPhone color="yellow" />
              <span className="text-white font-normal uppercase">
                +91-8815019860
              </span>
            </div>
            <div className="flex gap-x-2 my-2 items-center">
              <CgMail color="yellow" />
              <span className="text-white font-normal uppercase">
                Mindbulls02@gmail.com
              </span>
            </div>
          </div>
          <div
            id="form-container"
            className=" lg:w-1/2 xl:w-1/2 md:11/12 w-11/12 px-3"
          >
            <h2 className="my-3 text-2xl tracking-wide font-bold text-white contact-title">
              Contact
            </h2>
            <form onSubmit={handleSubmit(handleForm)} className="grid grid-cols-2 gap-7">
              <input
                type="text"
                required
                {...register('name')}
                placeholder="Full Name"
                className="py-2 px-1 rounded-sm"
              />
              <input
                type="email"
                required
                {...register('email')}
                placeholder="Email id"
                className="py-2 px-1 rounded-sm"
              />
              <input
                type="tel"
                required
                {...register('mobile')}
                maxLength={10}
                max={10}
                placeholder="Mobile / Telephone Number"
                className="py-2 px-1 rounded-sm"
              />
              <select {...register('course')} className="py-2 px-1 rounded-sm">
                <option>Select a Courses</option>
                <option value="Stock Market Foundation">
                  Stock Market Foundation
                </option>
                <option value="Advance Stock Market">
                  Advance Stock Market
                </option>
                <option value="Crypto Trading">Crypto Trading</option>
                <option value="Certification and other">
                  Certification and Other
                </option>
              </select>
              <select {...register('city')} className="py-2 px-1 rounded-sm">
              <option>--Select City--</option>
              <option value="Other">Other</option>
              <option value="Indore">Indore</option>
              <option value="Ujjain">Ujjain</option>
              <option value="Bhopal">Bhopal</option>
              <option value="Dewas">Dewas</option>
              <option value="Khargone">Khargone</option>
              <option value="Barwaha">Barwaha</option>
              <option value="Dhar">Dhar</option>
              <option value="Dhamnod">Dhamnod</option>
              <option value="Maheshwar">Maheshwar</option>
              <option value="Sanwer">Sanwer</option>
              <option value="Depalpur">Depalpur</option>
              <option value="Hatod">Hatod</option>
              <option value="Betma">Betma</option>
              <option value="Manpur">Manpur</option>
              <option value="Sanawad">Sanawad</option>
              <option value="Vadodara">Vadodara</option>
              <option value="Jabalpur">Jabalpur</option>
              <option value="Sagar">Sagar</option>
              <option value="Balaghat">Balaghat</option>
              <option value="Delhi">Delhi</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Pune">Pune</option>
              </select>
              <button className="bg-orange-500 shadow-lg text-white font-bold py-2 rounded-md">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactForm;
