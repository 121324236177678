import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa6';
const Breadcrumb = ({}) => {
  const location = useLocation()
  const currentPage = location.pathname.split("/")[1]
  console.log(currentPage);
  


  return (
    <div className="tab_list_warp">
      <p className="flex items-center gap-3">
        <Link to="/">Home</Link>
        <span>
          {" "}
          <FaAngleRight />{" "}
        </span>
        <Link> {currentPage}</Link>
      </p>
    </div>
  );
};

export default Breadcrumb;
