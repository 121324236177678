import React, { useState } from "react";
import whatsApp from "../../assets/icon/whatsapp.gif";
import ModalComponent from "../modal/modalComponent";
import EnquiryForm from "./EnquiryForm";


const WhatsApp = () => {

  return (
    <><a href="https://api.whatsapp.com/send?phone=918815019860"><div className="fixed flex bottom-12 cursor-pointer p-0 z-30 right-12 bg-[#0000004f] rounded-xl">
      <img src={whatsApp} alt="whats-app" className="w-[50px] h-[50px]" />
    </div></a>
   {/* {open===true && <ModalComponent setOpen={(v)=>{setOpen(v)}} children={<EnquiryForm setOpen={(v)=>{setOpen(v)}} />} />} */}
</>
  );
};

export default WhatsApp;
