import React, { useEffect } from "react";
import logo from "../../assets/mindbulls white front with clear background.png";
import Breadcrumb from "../breadcrumb";
import Aos from "aos";
import "aos/dist/aos.css";

function MainSection() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false, // Allow animations to repeat
      mirror: true, // Whether elements should animate out while scrolling past them
    });
  }, []);

  return (
    <>
      <section className="about_banner pt-24 lg:px-28">
        <div className="container">
          <Breadcrumb />
          <div className="row">
            <div className="col-md-6">
              <div className="about_banner_left">
                <span  data-aos="fade-up" className="text-2xl text-gray-200 font-semibold tracking-widest">
                  About Mindbulls
                </span>
                <h1  data-aos="fade-right" className="custom_heading">
                  A New Era Of Financial Trading Education
                  <strong>
                    {" "}
                    NISM-Certified Experienced <span>Mentors</span>
                  </strong>
                </h1>
                <p>
                  Where education meets trading excellence , Empowering you with
                  market mastery.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_banner_right">
                <figure className="animate_coinfilp">
                  <img  data-aos="zoom-in" data-src={logo} alt="img" src={logo} />
                </figure>
              </div>
            </div>
            <div className="about_vetor">
              <div className="about_vetor_first">
                <img
                  className=""
                  data-src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/about_dot.webp"
                  alt="img"
                  src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/about_dot.webp"
                />
              </div>
              <div className="about_vetor_second">
                <img
                  className=""
                  data-src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/about_green.webp"
                  alt="img"
                  src="https://www.gettogetherfinance.com/frontend_asset/main_pages/images/about_green.webp"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
