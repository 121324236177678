import React from "react";
import { coursesDetials } from "../../data/courseData";
import { HiMiniArrowRightCircle } from "react-icons/hi2";
import { FaUnlockKeyhole, FaUserGraduate } from "react-icons/fa6";
import sideImage from "../../assets/icon/coursebg.png";
import { BiSupport } from "react-icons/bi";
import { LiaCertificateSolid } from "react-icons/lia";
import { TiGroupOutline } from "react-icons/ti";
import { RxVideo } from "react-icons/rx";
import TestimonialCarousel from "../carousel/TestimonialsCarousel";
import sideImg2 from "../../assets/icon/courseIcon.webp";
import sideImg3 from "../../assets/icon/coin.png"

const CourseLandingSection = () => {
  return (
    <>
      <section
        className="w-screen lg:flex-row md:flex-row flex-col-reverse  flex items-center justify-evenly lg:h-screen md:h-screen h-fit px-3  mt-20 bgCourse  "
        // style={{
        //   background:
        //     "url('https://img.freepik.com/free-vector/white-arrow-background-abstract-border-start-up-business-vector_53876-143334.jpg?t=st=1722926349~exp=1722929949~hmac=9772e9267cba5440be51d2daadfef6a39c81cc44c67f905c220431fd74d278e6&w=996')",
        //   backgroundSize: "cover",
        // }}
      >
        <div className="lg:px-4 md:px-4 px-0 lg:w-1/2 md:w-1/2 w-full h-full flex gap-y-3 flex-col items-start justify-center">
          <h1 className="h1" data-aos="fade-right">
            Transform Your Financial Future with
            <br /> Expert Stock Market Courses
          </h1>
          <div className="flex gap-x-3 items-center" data-aos="fade-right">
            <FaUnlockKeyhole size={30} color="darkblue" />{" "}
            <h3 className="h3 m-0 text-blue-950 font-bold">
              Unlock your Potential
            </h3>
          </div>
          <p className="mt-4 text-xl" data-aos="fade-right">
            Ready to navigate the world of investing with confidence? <br />
            Whether you're a beginner or an experienced trader, our
            comprehensive stock market courses are designed to elevate your
            skills and achieve your financial goals.
          </p>
          <ul className="list-disc list-inside" data-aos="fade-up">
            <li className="text-lg font-bold">
              Expert Instruction: Learn from seasoned professionals with
              real-world experience.
            </li>
            <li className="text-lg font-bold" data-aos="fade-up">
              Practical Skills: Gain hands-on experience with real-time
              simulations and case studies.
            </li>
            <li className="text-lg font-bold" data-aos="fade-up">
              Supportive Community: Join a network of learners and experts ready
              to support your journey.
            </li>
          </ul>
          <div className="flex mt-5  gap-x-4">
            <button className="bg-slate-900 text-white py-2 px-3 rounded-lg">
              Download Course details
            </button>
            <button className="bg-slate-900 text-white py-2 px-3 rounded-lg">
              Explore
            </button>
          </div>
        </div>
        <div className="lg:w-1/3 h-full flex-col flex items-center justify-center sm:1/2 md:w-1/3 xl:w-1/3 w-full">
          <div className="flex h-0 flex-row items-center" data-aos="fade-left">
            {" "}
            <img
              src={sideImg2}
              className="w-20 relative top-10 right-28 lg:w-[200px] mx-auto animate_float_delayed"
              alt=""
              data-aos="flip-left"
            />
            <img
              src={"https://cdn3d.iconscout.com/3d/premium/thumb/businessman-trading-stock-using-mobile-app-6328270-5221318.png?f=webp"}
              className="w-20 relative top-10 left-28 lg:w-[200px] mx-auto animate_float_delayed"
              alt=""
              data-aos="flip-left"
            />
          </div>
          <img
            src={sideImage}
            className="w-96 lg:w-[350px] mx-auto animate_float"
            alt=""
            data-aos="zoom-out"
          />
          <div className="flex h-0 flex-row items-center" data-aos="fade-right">
            {" "}
            <img
              src={sideImg3}
              className="w-20 relative bottom-10 right-28 lg:w-[150px] mx-auto animate_float_delayed"
              alt=""
              data-aos="flip-right"
            />
            <img
              src={"https://cdn3d.iconscout.com/3d/premium/thumb/certificate-6244371-5117408.png?f=webp"}
              className="w-20 relative bottom-10  left-32 lg:w-[150px] mx-auto animate_float_delayed"
              alt=""
              data-aos="flip-right"
            />
          </div>
        </div>
      </section>
      <section className="w-screen px-3 py-20  pt-20 ">
        <div>
          <h1
            data-aos="fade-right"
            className="h1 my-4 cinzel text-orange-600 font-bold text-center"
          >
            Courses We Offer
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="grid lg:grid-cols-3 justify-center items-center grid-cols-1 gap-x-4 gap-y-8">
            {coursesDetials.map((course, index) => (
              <div
                data-aos="flip-right"
                key={index}
                className="flex items-center justify-center gap-y-5 mx-auto lg:w-2/3 w-10/12  flex-col"
              >
                {" "}
                <img
                  className="aspect-video rounded-lg hover:shadow-3xl duration-200 hover:shadow-slate-900 hover:scale-95 cursor-pointer"
                  src={course.Img}
                  alt={course.alt}
                />
                <h4 className="h4 text-center">{course.name}</h4>
                <ul className=" self-start">
                  {course?.topics?.map((item) => (
                    <div className="flex items-center gap-x-2 ">
                      <span>
                        <HiMiniArrowRightCircle />
                      </span>
                      <li>{item}</li>
                    </div>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-6 pb-5 bg-[#ff7f23]">
        <h1 className="h1 text-center py-6 font-bold " data-aos="fade-up">
          Why Choose Us ?
        </h1>
        <div className="grid lg:grid-cols-5 grid-cols-1 gap-y-4 items-center">
          <div
            className="mt-4 flex flex-col items-center gap-y-4"
            data-aos="fade-up"
          >
            <div className="size-24 ">
              <RxVideo className="h-fit w-full" />{" "}
            </div>
            <h2 className="h2 font-bold text-center  capitalize">
              Live Trading support
            </h2>
          </div>
          <div
            className="mt-4 flex flex-col items-center gap-y-4"
            data-aos="fade-up"
          >
            <div className="size-24 ">
              <FaUserGraduate className="h-fit w-full" />{" "}
            </div>
            <h2 className="h2 font-bold text-center  capitalize">
              Special mentorship
            </h2>
          </div>
          <div
            className="mt-4 flex flex-col items-center gap-y-4"
            data-aos="fade-up"
          >
            <div className="size-24 ">
              <TiGroupOutline className="h-fit w-full" />{" "}
            </div>
            <h2 className="h2 font-bold text-center  capitalize">
              One to one attention{" "}
            </h2>
          </div>
          <div
            className="mt-4 flex flex-col items-center gap-y-4"
            data-aos="fade-up"
          >
            <div className="size-24 ">
              {" "}
              <LiaCertificateSolid className="h-fit w-full" />
            </div>
            <h2 className="h2 font-bold text-center  capitalize">
              Certification courses
            </h2>
          </div>
          <div
            className="mt-4 flex flex-col items-center gap-y-4"
            data-aos="fade-up"
          >
            <div className="size-24">
              <BiSupport className="h-fit w-full" />{" "}
            </div>
            <h2 className="h2 font-bold text-center  capitalize">
              Lifetime free assistance
            </h2>
          </div>
        </div>
      </section>
      <TestimonialCarousel />
    </>
  );
};

export default CourseLandingSection;
